<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          Lista de usuários
          <v-spacer></v-spacer>
          <v-btn color="success" :to="{ name: 'usersCreate', params: {} }">
            <v-icon left>mdi-account-multiple-plus</v-icon>
            Cadastrar
          </v-btn>
        </v-card-title>
        <v-alert type="warning" outlined v-show="!checkUsers"
          >Nenhum usuário encontrado.</v-alert
        >
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="users"
          item-key="id"
          class="elevation-1"
          v-show="checkUsers"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn icon :to="{ name: 'usersEdit', params: { id: item.id } }">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";

import { USERS_GET, USER_SEARCH } from "@/store/actions/user.type";
import { USER_RESET_STATE } from "@/store/mutations/user.type";

export default {
  name: "ListarUsuarios",
  directives: { mask },
  props: {
    source: String
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(USER_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(USER_RESET_STATE),
      store.dispatch(USERS_GET)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    //Lista de Usuários
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    headers: [
      {
        text: "Nome",
        align: "left",
        value: "name"
      },
      // { text: "Instituição", value: "instituicao" },
      { text: "E-mail", value: "email" },
      { text: "Usuário", value: "username" },
      { text: "Editar", value: "actions", sortable: false }
    ],

    formIsValid: false,

    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    cpfRule: [
      v => !!v || "CPF é obrigatório",
      strCPF => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      }
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value.length > 0) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Informe um email válido";
        }
        return false;
      }
    ]
  }),
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    buscar() {
      store.dispatch(USER_SEARCH);
      // console.log("buscar");
    },
    //Fim Methods Lista Usuarios

    reset() {
      this.$refs.formUsuario.reset();
    }
  },
  mounted() {},
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["users", "checkUsers", "userSearch"])
  },
  watch: {}
};
</script>
